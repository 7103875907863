import React, { useState } from 'react';
import {
  CheckMark,
  Container,
  LanguageItem,
  LanguageList,
  // Title,
} from './styles';

interface Props {
  languages: Language[];
  handleLanguageChange: any;
}

interface Language {
  languageId: number;
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  value: number;
  label: string;
  languageCode: string;
}

const LanguageSelector: React.FC<Props> = ({
  languages,
  handleLanguageChange,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  const handleLanguageSelect = (language: Language): void => {
    setSelectedLanguage(language);
    handleLanguageChange(language);
  };

  return (
    <Container>
      {/* <Title>Select Your Language</Title> */}

      {languages && (
        <div
          style={{
            border: '0px solid #505050',
            borderRadius: '8px',
            boxShadow: '0 .5rem 1rem rgba(0, 0, 0, .15)',
          }}
        >
          <div
            style={{
              color: '#505050',
              lineHeight: '30px',
              padding: '20px 20px 12px 20px',
              backgroundColor: 'rgba(0, 0, 0, .03)',
            }}
          >
            <p style={{ fontSize: '1.5rem' }}>
              Please select your preferred language to start the survey
            </p>
            <i>
              Bitte wählen Sie Ihre bevorzugte Sprache, um die Umfrage zu
              starten.
            </i>
          </div>
          <div>
            <LanguageList>
              {languages.map((language: Language) => (
                <LanguageItem
                  key={language.name}
                  selected={
                    selectedLanguage?.languageId === language.languageId
                  }
                  onClick={() => handleLanguageSelect(language)}
                >
                  {selectedLanguage?.languageId === language.languageId && (
                    <CheckMark>✓</CheckMark>
                  )}
                  {language.name}
                </LanguageItem>
              ))}
            </LanguageList>
          </div>
        </div>
      )}
    </Container>
  );
};

// Exporting the Component
export default LanguageSelector;
