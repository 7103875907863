import styled from 'styled-components';
// Styled Components

interface SelectLanguagesProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 80%;
  /* background: radial-gradient(
    circle,
    rgba(30, 30, 30, 1) 0%,
    rgba(5, 5, 5, 1) 100%
  ); */
  /* color: white; */
  font-family: Arial, sans-serif;
  background-size: cover;
`;

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 300;
`;

export const LanguageList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 20px;
`;

export const LanguageItem = styled.li<SelectLanguagesProps>`
  width: 100%;
  font-weight: bold;
  font-size: 22px;
  padding: 10px 15px;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  background-color: ${props =>
    props.selected ? 'rgba(211, 211, 211, 0.2)' : 'transparent'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(211, 211, 211, 0.2);
  }
`;

export const CheckMark = styled.span`
  margin-right: 10px;
  color: #ffcc00;
`;
